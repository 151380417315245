import React, { createContext, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { NavLinkStyle } from '../NavLink';
import { CommonProps, NavLinkContainerContext } from '../internal/interfaces';
import { Colors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import { Surface, SurfaceElevation } from '../Surface';
import './SideNavigation.scss';

export interface ISideNavigationWrapperContext {
  collapsible: boolean;
}

export const SideNavigationWrapperContext =
  createContext<ISideNavigationWrapperContext | null>(null);

interface SideNavigationWrapperProps
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Manually set whether the navigation can be collapsed. This is useful when you do not have icons for your `NavLinks` and you do not want the menu to collapse on smaller screens.
   */
  collapsible?: boolean;
}

export const SideNavigationWrapper: React.VFC<SideNavigationWrapperProps> =
  React.forwardRef<HTMLDivElement, SideNavigationWrapperProps>(
    ({ className, children, id: idProp, collapsible = true, ...rest }, ref) => {
      const [cssPrefix] = useCSSPrefix();
      const id = useId(idProp);

      const value = useMemo(() => ({ collapsible }), [collapsible]);

      return (
        <SideNavigationWrapperContext.Provider value={value}>
          <NavLinkContainerContext.Provider value="sidenavigation">
            <div
              {...rest}
              ref={ref}
              id={id}
              className={clsx([
                `${cssPrefix}-side-navigation-wrapper`,
                className,
              ])}
            >
              {children}
            </div>
          </NavLinkContainerContext.Provider>
        </SideNavigationWrapperContext.Provider>
      );
    }
  );

export type PageContentElement = 'main' | 'section' | 'div';

export interface SideNavigationPageContentProps
  extends React.ComponentPropsWithoutRef<'main'> {
  /**
   * Optionally specify the root element of the SideNavigation page content from the following choices :
   * @default 'main'
   */
  as?: PageContentElement;
}

export const SideNavigationPageContent: React.VFC<SideNavigationPageContentProps> =
  React.forwardRef<HTMLDivElement, SideNavigationPageContentProps>(
    ({ as = 'main', className, children, id: idProp, ...rest }, ref) => {
      const [cssPrefix] = useCSSPrefix();
      const id = useId(idProp);
      const Component: PageContentElement = as;

      const { collapsible } = useContext(SideNavigationWrapperContext) || {};

      return (
        <Component
          {...rest}
          ref={ref}
          id={id}
          className={clsx([
            `${cssPrefix}-side-navigation-page-content`,
            { 'no-collapse': !collapsible },
            className,
          ])}
        >
          {children}
        </Component>
      );
    }
  );

export type SideNavigationContextProps = Pick<
  SideNavigationProps,
  'color' | 'linkStyle'
>;

export const SideNavigationContext =
  createContext<SideNavigationContextProps | null>(null);

export interface SideNavigationProps
  extends React.ComponentPropsWithoutRef<'nav'>,
    CommonProps {
  /**
   * Specify the color of the SideNavigation.
   * @default 'primary'
   */
  color?: Colors;
  /**
   * Specify the elevation of the SideNavigation.
   * @default 1
   */
  elevation?: SurfaceElevation;
  /**
   * Specify the style of NavLinks within the SideNavigation.
   */
  linkStyle?: NavLinkStyle;
  /**
   * Specify the content to render within the SideNavigation.
   */
  children: React.ReactNode;
}

export const SideNavigation = React.forwardRef<
  HTMLElement,
  SideNavigationProps
>(
  (
    {
      color = 'primary',
      elevation = 1,
      linkStyle = 'default',
      className,
      children,
      style,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const id = useId(idProp);
    const value = useMemo(() => ({ color, linkStyle }), [color, linkStyle]);
    const { collapsible } = useContext(SideNavigationWrapperContext) || {};

    return (
      <SideNavigationContext.Provider value={value}>
        <NavLinkContainerContext.Provider value="sidenavigation">
          <Surface
            {...rest}
            ref={ref}
            id={id}
            style={{ ...style }}
            className={clsx(
              `side-navigation`,
              { 'no-collapse': !collapsible },
              `side-navigation-color-${color}`,
              'side-navigation-content-base',
              'side-navigation-content-grow',
              className
            )}
            as="nav"
            radii="none"
            elevation={elevation}
          >
            {children}
          </Surface>
        </NavLinkContainerContext.Provider>
      </SideNavigationContext.Provider>
    );
  }
);

interface ISideNavigationContent
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the content to render inside of the SideNavigation content.
   */
  children: React.ReactNode;
  /**
   * Specify where to render the content within the SideNavigation.
   */
  section?: 'start' | 'middle' | 'end';
}

export const SideNavigationContent: React.VFC<ISideNavigationContent> =
  React.forwardRef<HTMLDivElement, ISideNavigationContent>(
    ({ children, section = 'start', className, id: idProp, ...props }, ref) => {
      const id = useId(idProp);
      const { collapsible } = useContext(SideNavigationWrapperContext) || {};

      return (
        <div
          {...props}
          ref={ref}
          id={id}
          className={clsx(className, {
            'side-navigation-content-start': section === 'start',
            'side-navigation-content-grow': section === 'middle',
            'side-navigation-content-end': section === 'end',
            'side-navigation-icons': collapsible,
          })}
        >
          {children}
        </div>
      );
    }
  );
